import React from "react";
import { Features } from "./features";

export const About = (props) => {
  return (
    <div id="about" style={{ width: "100vw" }}>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-12">
            {" "}
            <h1 style={{ color: "black" }}>Welcome to our URL Shortener</h1>
            <p style={{ color: "black" }}>
              {props.data ? props.data.paragraph : "loading..."}
            </p>
          </div>
          <div className="col-xs-12 col-md-12">
            <div className="about-text">
              <Features />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
