import React, { useEffect, useState } from "react";
import { base_url } from "../constants/constants";

export const Features = () => {
  const [totalLinksCreated, setTotalLinksCreated] = useState(0);
  const [totalLinksClicked, setTotalLinksClicked] = useState(0);

  const fetchStats = async () => {
    try {
      // Make API call to fetch total links created
      const createdResponse = await fetch(base_url + "/total-links-created");
      // console.log("createdResponse", createdResponse);
      const createdData = await createdResponse.json();
      setTotalLinksCreated(createdData.totalLinksCreated);

      // Make API call to fetch total links clicked
      const clickedResponse = await fetch(base_url + "/total-links-clicked");
      const clickedData = await clickedResponse.json();
      setTotalLinksClicked(clickedData.totalLinksClicked);
    } catch (error) {
      console.error("Error fetching stats:", error);
    }
  };

  useEffect(() => {
    fetchStats();
  }, []);
  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          {/* <h2>Stats</h2> */}
        </div>
        <div className="row">
          <div className="col-xs-6 col-md-6">
            <i className="fa fa-link"></i>
            <h3>Total Links Created</h3>
            <p style={{ color: "black", fontSize: "30px", fontWeight: "500" }}>
              {totalLinksCreated}
            </p>
          </div>
          <div className="col-xs-6 col-md-6">
            <i className="fa fa-external-link"></i>
            <h3>Total Links Clicked</h3>
            <p style={{ color: "black", fontSize: "30px", fontWeight: "500" }}>
              {totalLinksClicked}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
