import { Button, FormControl, OutlinedInput, Tooltip } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { base_url } from "../constants/constants";

export const Header = () => {
  const [url, setUrl] = useState("");
  const [shortenedUrls, setShortenedUrls] = useState([]);
  const [error, setError] = useState(null);
  const [copiedIndexes, setCopiedIndexes] = useState([]);
  const adContainerRef = useRef(null);
  // Function to save data to localStorage
  const saveDataToLocalStorage = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
  };

  // Function to retrieve data from localStorage
  const getDataFromLocalStorage = (key) => {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : [];
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!url) {
      setError("Please enter a URL.");
      return;
    }

    try {
      const response = await fetch(base_url + "/shorten", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ url }),
      });

      const data = await response.json();
      if (shortenedUrls.length >= 4) {
        // If the limit is reached, remove the first element
        const updatedShortenedUrls = [...shortenedUrls.slice(1), data];
        setShortenedUrls(updatedShortenedUrls);
        // Save updated data to localStorage
        saveDataToLocalStorage("shortenedUrls", updatedShortenedUrls);
      } else {
        // If the limit is not reached, add the new shortened URL
        setShortenedUrls([...shortenedUrls, data]);
        // Save data to localStorage
        saveDataToLocalStorage("shortenedUrls", [...shortenedUrls, data]);
      }
      setUrl("");
    } catch (error) {
      console.error("Error:", error.response);
    }
  };

  useEffect(() => {
    // Retrieve data from localStorage when the component mounts
    const savedShortenedUrls = getDataFromLocalStorage("shortenedUrls");
    if (savedShortenedUrls.length > 0) {
      setShortenedUrls(savedShortenedUrls);
    }
  }, []);

  useEffect(() => {
    if (url.length) {
      setError("");
    }
  }, [url]);

  const handleCopyClick = (index, shortenedUrl) => {
    navigator.clipboard.writeText(shortenedUrl);
    setCopiedIndexes([...copiedIndexes, index]);
    setTimeout(() => {
      setCopiedIndexes(copiedIndexes.filter((i) => i !== index));
    }, 1000);
  };

  const handleShortenedUrlClick = async (e, shortenedUrl) => {
    e.preventDefault();
    window.location.href = shortenedUrl;
  };

  const handleDeleteClick = (index) => {
    const updatedShortenedUrls = [...shortenedUrls];
    updatedShortenedUrls.splice(index, 1);
    setShortenedUrls(updatedShortenedUrls);
    // Save updated data to localStorage
    saveDataToLocalStorage("shortenedUrls", updatedShortenedUrls);
  };
  useEffect(() => {
    if (adContainerRef.current && !adContainerRef.current.firstChild) {
      const configScript = document.createElement("script");
      configScript.textContent = `
        var atOptions = {
          'key' : 'ad642b64a4f7f171c7672ac95062c32e', // Replace with your actual key
          'format' : 'iframe',
          'height' : 90,
          'width' : 728,
          'params' : {}
        };
      `;
      adContainerRef.current.appendChild(configScript);

      const adScript = document.createElement("script");
      adScript.type = "text/javascript";
      adScript.src =
        "//www.topcreativeformat.com/ad642b64a4f7f171c7672ac95062c32e/invoke.js"; // Replace with your actual key
      adContainerRef.current.appendChild(adScript);
    }
  }, []);

  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <div
                  style={{
                    fontSize: "46px",
                    fontWeight: "500",
                  }}
                >
                  FREE URL Shortener
                </div>
                <div
                  style={{
                    fontSize: "23px",
                    fontWeight: "500",
                    marginBottom: "80px",
                  }}
                >
                  URL shortener tool provided by{" "}
                  <a href="https://codart-technologies.com">
                    <span style={{ color: "white", cursor: "pointer" }}>
                      CODART Technologies
                    </span>
                  </a>
                </div>
                <form onSubmit={handleSubmit}>
                  <div
                    style={{ display: "flex", gap: "10px" }}
                    noValidate
                    autoComplete="off"
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <OutlinedInput
                        style={{
                          fontSize: "16px",
                          background: "white",
                          border: "none",
                        }}
                        placeholder="Enter URL here..."
                        onChange={(e) => setUrl(e.target.value)}
                        value={url}
                      />
                    </FormControl>
                    <Button
                      style={{
                        paddingLeft: "40px",
                        paddingRight: "40px",
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                      }}
                      variant="contained"
                      onClick={handleSubmit}
                    >
                      Shorten URL
                    </Button>
                  </div>
                  {error && (
                    <div
                      style={{
                        color: "red",
                        marginTop: "10px",
                        fontSize: "20px",
                      }}
                    >
                      {error}
                    </div>
                  )}
                </form>
                <div style={{ marginTop: "50px", marginBottom: "50px" }}>
                  <div ref={adContainerRef} />
                </div>
                {shortenedUrls.map((shortenedUrl, index) => (
                  <div
                    id="url-output"
                    key={index}
                    style={{
                      background: "#1976d2",
                      borderRadius: "20px",
                      marginTop: "20px",
                      display: "flex",
                      textAlign: "start",
                      padding: "20px",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      id="original-url"
                      style={{ fontSize: "20px", color: "white" }}
                    >
                      {shortenedUrl.original_url}
                    </div>
                    <div
                      id="copy-dlt-buttons"
                      style={{
                        fontSize: "18px",
                        display: "flex",
                        gap: "20px",
                        alignItems: "center",
                      }}
                    >
                      <a
                        style={{ color: "white" }}
                        href={shortenedUrl.shortened_url}
                        onClick={(e) =>
                          handleShortenedUrlClick(e, shortenedUrl.shortened_url)
                        }
                      >
                        {shortenedUrl.shortened_url}
                      </a>
                      <div style={{ display: "flex", gap: "20px" }}>
                        <Tooltip
                          title={
                            copiedIndexes.includes(index) ? "Copied!" : "Copy"
                          }
                          placement="top"
                          arrow
                          open={copiedIndexes.includes(index)}
                        >
                          <ContentCopyOutlinedIcon
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleCopyClick(index, shortenedUrl.shortened_url)
                            }
                          />
                        </Tooltip>
                        <Tooltip title="Delete" placement="top" arrow>
                          <CloseIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => handleDeleteClick(index)}
                          />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                ))}
                <div
                  style={{ marginTop: "50px" }}
                  id="container-3161ae7cf08101a4327088a46ffffd4e"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
